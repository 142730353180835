<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="evaluate-list" v-if="evaluateList.length > 0">
				<div class="item" v-for="(item, index) in evaluateList" :key="index">
					<div class="item-head">
						<img class="pic" :src="item.portrait" @error="avatarLoadError" alt="">
						<div class="info-wrap">
							<div class="name">{{ item.nick_name }}</div>
							<div class="text">{{ item.create_time | parseTime('{y}-{m}-{d}') }}</div>
						</div>
					</div>
					<div class="item-desc">{{ item.order_goods.specification }}</div>
					<div class="item-text">{{ item.content }}</div>
					<ul class="item-image" v-if="item.img_urls.length > 0">
						<li v-for="(image, index2) in item.img_urls" :key="index2"><img :src="image.img_url" alt=""></li>
					</ul>
				</div>
			</div>
			<div v-else>
				<el-empty :description="$t('mobile.empty.comment')"></el-empty>
			</div>
		</div>
	</div>
</template>

<script>
import { getGoodsComments } from '@/api/mobile/store';
export default {
	data() {
		return {
			evaluateList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
		}
	},
	created() {
		this.getEvaluateList()
	},
	methods: {
		avatarLoadError(e) {
			e.target.src = this.$imageErrorUser;
			e.target.style.objectFit = 'fill';
		},
		getEvaluateList() {
			let param = {
				goods_id: this.$route.params.id,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize,
				app_key: this.$store.getters.appKey
			}
			getGoodsComments(param).then(response => {
				this.evaluateList = response.data.list
				this.pageTotal = response.data.total
			})
		},
	}
}
</script>

<style lang="scss" scoped>
.mhome-wrap {
	background-color: #fff;
}

.evaluate-list {
	padding: 0.256rem 0.384rem;

	.item {
		padding: 0.256rem 0;
		border-bottom: 1px solid #E5E5E5;

		.item-head {
			display: flex;

			.pic {
				width: 1.024rem;
				height: 1.024rem;
				border-radius: 50%;
				object-fit: cover;
			}

			.info-wrap {
				margin-left: 0.256rem;

				.name {
					font-size: 0.384rem;
					line-height: 0.512rem;
					margin-bottom: 0.096rem;
				}

				.text {
					color: #999999;
					font-size: 0.32rem;
					line-height: 1em;
					margin-top: 0.128rem;
				}
			}
		}

		.item-desc {
			color: #999999;
			font-size: 0.384rem;
			line-height: 0.48rem;
			padding-left: 0.192rem;
			margin-top: 0.256rem;
			margin-left: 0.064rem;
			border-left: 0.064rem solid #E5E5E5;
		}

		.item-text {
			font-size: 0.384rem;
			line-height: 0.48rem;
			margin-top: 0.256rem;
		}

		.item-image {
			display: flex;

			li {
				flex-shrink: 0;
				width: 3.52rem;
				height: 3.52rem;
				margin-right: 0.32rem;
				margin-top: 0.32rem;
				&:nth-child(3n) {
					margin-right: 0;
				}

				img {
					width: 100%;
					height: 100%;
					border-radius: 0.16rem;
					object-fit: cover;
				}
			}
		}
	}

	.item:last-child {
		border-bottom: none;
	}
}
</style>